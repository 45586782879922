@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GT Super Text";
  src: url("./fonts/GTSuperText-Black.eot");
  src: url("./fonts/GTSuperText-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/GTSuperText-Black.woff2") format("woff2"),
    url("./fonts/GTSuperText-Black.woff") format("woff"),
    url("./fonts/GTSuperText-Black.ttf") format("truetype"),
    url("./fonts/GTSuperText-Black.svg#GTSuperText-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    url("./fonts/SourceSansPro-Light.woff2") format("woff2"),
    url("./fonts/SourceSansPro-Light.woff") format("woff"),
    url("./fonts/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url("./fonts/SourceSansPro-Regular.woff2") format("woff2"),
    url("./fonts/SourceSansPro-Regular.woff") format("woff"),
    url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url("./fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("./fonts/SourceSansPro-SemiBold.woff") format("woff"),
    url("./fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url("./fonts/SourceSansPro-Bold.woff2") format("woff2"),
    url("./fonts/SourceSansPro-Bold.woff") format("woff"),
    url("./fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

body {
  font-family: "Source Sans Pro";
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GT Super Text";
}

button {
  font-family: "Source Sans Pro";
  font-weight: 700;
}

main {
  flex: 1 0 auto;
}
